<template>
    <div class="topic-list">
        <div class="content">
            <div style="height:60px;background:#FFF;margin-bottom:10px;">

                <div style="overflow:auto;padding: 10px 0px;" class="top_search">
                    <div style="float:left;width:88px;height:38px;line-height: 38px;text-align: center;background:#F5F7FA;border-radius:5px;border: 1px solid #e4e7ed;margin-left: 10px;">话题</div>
                    <el-input placeholder="请输入内容" v-model="like" class="input-with-select" @input="getHotTopicList" style="float:left;width:calc( 100% - 200px );"  >

                        <el-button slot="append" icon="el-icon-search" @click="getHotTopicList" class="op sc"></el-button>
                    </el-input>
                    <div style="float:left;width:80px;height:40px;line-height:20px;text-align:center;background:#FFF;border-radius:5px;margin-left:10px;cursor: pointer;" class="op sc hand">
                        <div @click="createTopic">
                            <svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" viewBox="0 0 448 512" width="14" height="14" style="fill: rgb(16, 16, 16);"><path d="M440.667 182.109l7.143-40c1.313-7.355-4.342-14.109-11.813-14.109h-74.81l14.623-81.891C377.123 38.754 371.468 32 363.997 32h-40.632a12 12 0 0 0-11.813 9.891L296.175 128H197.54l14.623-81.891C213.477 38.754 207.822 32 200.35 32h-40.632a12 12 0 0 0-11.813 9.891L132.528 128H53.432a12 12 0 0 0-11.813 9.891l-7.143 40C33.163 185.246 38.818 192 46.289 192h74.81L98.242 320H19.146a12 12 0 0 0-11.813 9.891l-7.143 40C-1.123 377.246 4.532 384 12.003 384h74.81L72.19 465.891C70.877 473.246 76.532 480 84.003 480h40.632a12 12 0 0 0 11.813-9.891L151.826 384h98.634l-14.623 81.891C234.523 473.246 240.178 480 247.65 480h40.632a12 12 0 0 0 11.813-9.891L315.472 384h79.096a12 12 0 0 0 11.813-9.891l7.143-40c1.313-7.355-4.342-14.109-11.813-14.109h-74.81l22.857-128h79.096a12 12 0 0 0 11.813-9.891zM261.889 320h-98.634l22.857-128h98.634l-22.857 128z"></path></svg>
                            <div >我要创建</div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="overflow: hidden;padding:10px 15px;margin-bottom: 5px; background: #FFFFFF;" v-for="(item,index) in topic_list" :key="index">
                <div class="hand op sc" style="float: left;width: 92px;height: 92px;"  @click="a('/topic/info/'+item.name)">
                    <img :src="item.bgimg+'-80x80'" style="width:100%;height:100%;" />
                </div>
                <div style="float: left; padding:  0 10px;position: relative;height: 92px;width:550px;">

                    <div style="margin-bottom: 5px;width: 100%;color: #333;font-weight: bold;text-overflow: ellipsis;white-space: nowrap;word-wrap: normal;cursor: pointer;" class="hand op sc" @click="a('/topic/info/'+item.name)">
                        <span class="top1" v-if="index == 0">TOP{{index+1}}</span>
                        <span class="top2" v-if="index == 1 || index == 2 ">TOP{{index+1}}</span>
                        <span class="top" v-if="index > 2">{{index+1}}</span>
                        <span style="text-decoration: none;">#{{item.name}}#</span>
                    </div>
                    <!--             		<div style="font-size: 12px;" v-if="item.detail == null || item.detail == ''" >此话题暂未设置导语</div> -->
                    <div style="font-size: 12px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{item.detail}}</div>
                    <div style="position: absolute;left: 8px;bottom: 20px;color: #808080;font-size: 12px;overflow: hidden;">
                        <div style="float: left;" v-if="item.clickcount == null">阅读数：<span style="color: #FA8A00;font-weight: bold;margin-right: 16px;">0</span></div>
                        <div style="float: left;" v-else>阅读数：<span style="color: #FA8A00;font-weight: bold;margin-right: 16px;">{{item.clickcount}}</span></div>

                        <div style="float: left;">创建人：<span style="color: #333;" class="hand op sc" @click="a('/user/info/'+item.uuid)">{{item.nickname}}</span></div>
                    </div>
                </div>
            </div>
            <NoData detail="暂无数据，请更换关键词再试" v-if="topic_list.length == 0" ></NoData>
            <div style="text-align:center;padding:20px 0;background:#FFF; margin:10px 0;color:#666;font-size:15px;" v-if="topic_list.length % 10 == 0">加载中 <i class="el-icon-loading"></i></div>
        </div>

        <div class="right_content">
            <div class="div">
                <div class="title_pic" style="margin-bottom:15px; padding: 10px 0 0 0" >
                    <div class="title" style="color:#000;border-left:6px solid #031F88;padding-left:10px;">我参与的话题</div>
                </div>
                <div class="text">
                    <div style="overflow: hidden;height: 30px;line-height: 30px;" v-for="(item,index) in my_topic_list" :key="index" @click="a('/topic/info/'+item.name)" class="op sc hand">
                        <div style="float: left;width:170px; text-overflow: ellipsis;white-space: nowrap;letter-spacing:1px;cursor: pointer;">#{{item.name}}#</div>
                        <div style="float: right;color: #a6a6a6;">{{item.clickcount}}</div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import NoData from "../../components/common/NoData";

    export default {
        name:"list",
        components: {
            NoData
        },
        computed: {
            user() {
                return this.$store.state.user
            },
            is_login(){
                return this.$store.state.is_login
            }
        },
        data () {
            return {
                topic_list:[],
                my_topic_list:[],
                like:"",
                page:1,
                more: 1,
                pageSize:10,
            }
        },
        props:{

        },
        created:function(){

        },
        mounted: function () {
            var that = this;
            this.getHotTopicList();
            this.getMyHotTopicList();
            this.$EventBus.$emit("close_im", {is_close: 0});

            window.onscroll = function(){
                //变量scrollTop是滚动条滚动时，距离顶部的距离
                var scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
                //变量windowHeight是可视区的高度
                var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
                //变量scrollHeight是滚动条的总高度
                var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
                //滚动条到底部的条件

                if(scrollHeight - scrollTop - windowHeight <= 0){
                  if (that.more == 1) {
                    that.getHotTopicList();
                  }else{
                    console.log("已加载完");
                  }
                }
            }
        },
        methods:{
            createTopic(){
              if (!this.utils.toAuth(1)) {
                return false
              }
              this.utils.a('/topic/add');
            },
            getHotTopicList(){
                var that = this;
                var params = {};
                params.like = that.like;
                params.page = that.page;
                params.pageSize = that.pageSize;
                that.newApi.getHotTopicList(params).then((res) =>{
                  for (const item of res.data) {
                    this.topic_list.push(item)
                  }
                  if (res.data.length < 10) {
                    this.more = 0;
                  } else {
                    this.page ++;
                  }
                });
            },
            getMyHotTopicList:function(){
                var that = this;
                var params = {};
                that.newApi.getMyHotTopicList(params).then((res) =>{
                    that.my_topic_list = res.data;
                });
            },
        }
    }

</script>

<style scoped>
    .topic-list{
        min-height: 100vh;
        margin: 0 auto;
        margin-top: 110px;
        width: 1500px;
        overflow: hidden;
        background:#f0f0f0;
    }
    .topic-list .content{
        width:1200px; float: left;;
    }
    .right_content{
        width:280px; float:left; margin-left: 10px;
    }
    .top{
        display: inline-block;
        width: 44px;
        height: 16px;
        margin-right: 6px;
        margin-top: 2px;
        overflow: hidden;
        text-align: center;
        line-height: 16px;
        color: #fff;
        background: #afb5c6;
        border-radius: 8px;
        font-weight: normal;
        vertical-align: text-top;
    }
    .top1{
        display: inline-block;
        width: 44px;
        height: 16px;
        margin-right: 6px;
        margin-top: 2px;
        overflow: hidden;
        text-align: center;
        line-height: 16px;
        color: #fff;
        background: #df3e3e;
        border-radius: 8px;
        font-weight: normal;
        vertical-align: text-top;
    }
    .top2{
        display: inline-block;
        width: 44px;
        height: 16px;
        margin-right: 6px;
        margin-top: 2px;
        overflow: hidden;
        text-align: center;
        line-height: 16px;
        color: #fff;
        background: #fa7d3c;
        border-radius: 8px;
        font-weight: normal;
        vertical-align: text-top;
    }
    /*我参与的话题*/
    .topic-list .div{
        background:#FFF;position:relative; min-height: 200px;
    }
    .div .title_pic{
        overflow:hidden;
    }
    .div .title_pic .title{
        color:#031F88;font-size:20px;font-weight:700;
        max-height:50px;line-height:25px; float:left;padding:0px 15px;
    }
    .div .title_pic .pic{
        float:right;padding-right: 15px;
    }
    .div .text{
        color:#666; margin-top:10px;
        white-space: pre-wrap;
        overflow: hidden;
        padding:0px 15px;
        /*max-height:88px;overflow:hidden;line-height:22px; position:relative;*/
    }
</style>
